import React from 'react';
import { useAureliaI18n } from '../../react-hooks/useAureliaI18n';

const AllMenuItems = ({
  menu,
  isPresentInSearch,
  ItemLabel,
  router,
  isActive,
}) => {
  const { t } = useAureliaI18n();

  return (
    menu &&
    menu.map((item, mi) => {
      if (!item.items || item.name === 'user') return null;

      let isItemLabelShown = false;
      let isGroupLabelShown = {};

      return (
        <React.Fragment key={`item-${mi}`}>
          {item.items.groups &&
            Object.keys(item.items.groups).map((group, index) => (
              <React.Fragment key={`group-${group}-${index}`}>
                {item.items.groups[group].map((subItem, i) => {
                  if (isPresentInSearch(t(group), subItem.label)) {
                    const itemLabel = !isItemLabelShown && (
                      <li className="menu-header hidden-xs">
                        {t(item.label)}
                      </li>
                    );
                    isItemLabelShown = true;

                    const groupLabel = !isGroupLabelShown[group] && (
                      <li className="menu-subheader hidden-xs">
                        {t(group)}
                      </li>
                    );
                    isGroupLabelShown[group] = true;

                    return (
                      <React.Fragment key={`subitem-${subItem.label}`}>
                        {itemLabel}
                        {groupLabel}
                        <li
                          className={`menu-subitem ${
                            isActive(subItem, router?.currentInstruction)
                              ? 'active'
                              : ''
                          }`}
                        >
                          <ItemLabel
                            subItem={subItem}
                            item={{ ...item, label: 'all' }}
                          />
                        </li>
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
              </React.Fragment>
            ))}
          {item.items.noGroup && (
            <React.Fragment key="noGroup">
              {item.items.noGroup.map((subItem) => {
                if (isPresentInSearch('noGroup', subItem.label, 'all')) {
                  const itemLabel = !isItemLabelShown && (
                    <li className="menu-header hidden-xs">
                      {t(item.label)}
                    </li>
                  );
                  isItemLabelShown = true;

                  return (
                    <React.Fragment key={`subitem-${subItem.label}`}>
                      {itemLabel}
                      <li
                        className={`menu-subitem ${
                          isActive(subItem, router?.currentInstruction)
                            ? 'active'
                            : ''
                        }`}
                      >
                        <ItemLabel
                          subItem={subItem}
                          item={{ ...item, label: 'all' }}
                        />
                      </li>
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    })
  );
};

export default AllMenuItems;