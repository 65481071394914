import {bindable, bindingMode, customElement, inject} from "aurelia-framework";
import {Router} from "aurelia-router";
import {Client} from "../api/client";

@inject(Client, Router)
@customElement('duplicates-display')
export class DuplicatesDisplay {

    @bindable({defaultBindingMode: bindingMode.oneWay}) reference;

    client;
    router;

    list;
    link;

    constructor(client, router) {
        this.client = client;
        this.router = router;
    }

    bind() {
        if (!this.reference || !this.reference.id || !this.reference.modelId) {
            return;
        }

        const [moduleId, modelId] = this.reference.modelId.split('/');

        this.list = null;
        this.link = this.router.generate('merge', {moduleId: moduleId, modelId: modelId, id: this.reference.id});

        this.client.head('merge/duplicates-exists/' + this.reference.modelId).then(() => {
            this.client.get('merge/duplicates/' + this.reference.modelId + '/' + this.reference.id + '?nodependers').then(list => {
                if (list.length && 1 < list.length) {
                    this.list = list;
                }
            });
        });
    }
}
