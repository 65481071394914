import {customElement, bindable, inject, LogManager} from 'aurelia-framework';
import {Client} from '../api/client';
import * as _ from 'lodash';
import {LocaleService} from "../i18n/locale-service";
import {I18N} from 'aurelia-i18n';

const logger = LogManager.getLogger('object-display');

/**
 * Class to display an object or a list of objects as a table (label value)
 */
@customElement('object-display')
@inject(Client, I18N)
export class ObjectDisplay
{
    @bindable object;
    @bindable properties = [];

    constructor(client, i18n)
    {
        this.client = client;
        this.i18n = i18n;
    }

    bind()
    {
        this.columns = [];

        logger.debug("Binding changed", this);

        this.client.get(this.object.modelId + '/list?_locale=' + this.i18n.getLocale(), 60).then(list => {
            const columns = [];

            _.each(this.properties, (property) => {

                 let column = _.find(list.columns, (o) => { return o.property === property});

                 if (!column) {
                     logger.error('Column not found ' + property);
                     return;
                 }

                 columns.push(column);
            });

            this.columns = columns;
        });
    }
}
