import React, { useEffect } from 'react';

const Logo = () => {
  return (
    <a href="/" className="navbar-brand">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21000 21000">
        <path
          fill="#FFF"
          d="M19329 142h1300l54 56-54 56-271 110-3412 334-4875 1224-3955 1782-1083 612-217 167-108 56h-55L4271 6208 3079 7377l-379 501-325 667-163 446v611l54 224 163 333 108 168 596 612 1463 1113 1625 835 2221 779 1408 333 109 112-55 56h-812l-3576-557-2328-723-1085-558-541-389-758-779-270-390-327-889-107-502 54-667 108-390 272-557 379-556 1733-1781 1408-1057 1354-835 4388-1781 3575-891 5958-723Zm-8017 6735 4550 667 1951 612 920 445 704 446 596 555v56l216 279 218 222 270 558 163 555v1003l-271 779-380 612-216 222-108 167v56l-55 56h-54l-271 278v56l-595 611-2654 2004-1030 612-2600 1114-2275 723-4170 890-4929 445H425l-55-56 55-54h54l3954-557 4713-1225 4116-1836 109-112 216-111h55l2166-1391 1517-1225 812-889 271-390 433-1058v-556l-54-223-162-334-108-166-704-725-759-612-758-501-1192-612-2059-779-1896-445-53-56 53-56 163-54Z"
        />
      </svg>
    </a>
  );
};

export default Logo;
