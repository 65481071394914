import React from 'react';
import { useAureliaI18n } from '../../react-hooks/useAureliaI18n';

const MobileNav = ({ router, user, logout }) => {
  const { t } = useAureliaI18n();
  return (
    <>
      <div id="navbar-secondary" className="collapse navbar-collapse">
        <ul className="nav navbar-nav">
          <li>
            <a
              href={`/view/user/detail?id=${user.id}`}
              style={{ cursor: 'pointer' }}
            >
              <span className="nav-icon">
                <i className="fa fa-user fa-sm"></i>
              </span>
              <span className="nav-label visible-xs">{user.username}</span>
            </a>
          </li>

          <>
            <li>
              <a
                href="/view/notification-center/notifications"
                title={t('notification.go-to-notifications')}
              >
                <span className="nav-icon">
                  <i className="fa fa-bell fa-sm" style={{ top: '3px' }}></i>
                </span>
                <span className="nav-label visible-xs">
                  Benachrichtigungen öffnen
                </span>
              </a>
            </li>
            <li>
              <a href="/view/todo/list" title={t('todo.go-to-todo')}>
                <span className="nav-icon">
                  <i
                    className="fa fa-list-check fa-sm"
                    style={{ top: '3px' }}
                  ></i>
                </span>
                <span className="nav-label visible-xs">Zur Aufgabenliste</span>
              </a>
            </li>
          </>

          <li>
            <a onClick={logout} href="#" style={{ cursor: 'pointer' }}>
              <span className="nav-icon">
                <i className="fa fa-right-from-bracket fa-sm"></i>
              </span>
              <span className="nav-label visible-xs">Abmeldung</span>
            </a>
          </li>
        </ul>
      </div>

      <div className="details-row">
        {router?.currentInstruction?.config?.navModel?.breadcrumbs && (
          <ol className="breadcrumb">
            {router?.currentInstruction?.config?.navModel?.breadcrumbs.map(
              (breadcrumb, index) => (
                <li key={index}>
                  {breadcrumb.viewId ? (
                    <a
                      href={breadcrumb.url}
                      dangerouslySetInnerHTML={{
                        __html: t(breadcrumb.label),
                      }}
                    />
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(breadcrumb.label),
                      }}
                    />
                  )}
                </li>
              )
            )}
          </ol>
        )}
      </div>
    </>
  );
};

export default MobileNav;
