import React from 'react';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';

const Breadcrumbs = ({ router }) => {
  const { t } = useAureliaI18n();
  return router?.currentInstruction?.config?.navModel?.breadcrumbs?.length >
    0 ? (
    <ol className="breadcrumb" style={{ display: 'flex' }}>
      {router?.currentInstruction?.config?.navModel?.breadcrumbs.map(
        (breadcrumb, index) => (
          <li key={index} style={{ display: 'flex', alignSelf: 'flex-end' }}>
            {breadcrumb.viewId ? (
              <a
                href={breadcrumb.url}
                style={{ display: 'flex' }}
                dangerouslySetInnerHTML={{
                  __html: t(breadcrumb.label),
                }}
              />
            ) : (
              <span
                style={{ display: 'flex', gap: '6px' }}
                dangerouslySetInnerHTML={{
                  __html: t(breadcrumb.label),
                }}
              />
            )}
          </li>
        )
      )}
    </ol>
  ) : (
    <></>
  );
};

export default Breadcrumbs;
