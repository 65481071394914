import { DialogController } from 'aurelia-dialog';
import { inject } from 'aurelia-framework';
import { BindingSignaler } from 'aurelia-templating-resources';
import Client from '../api/client';
@inject(Client, DialogController, BindingSignaler)
export class MultiPricesForm {
    currencies;

    constructor(client, controller, signaler) {
        this.client = client;
        this.controller = controller;
        this.signaler = signaler;
    }

    async activate(value) {
        this.currencies = value.currencies;
        this.currencyConfig = value.currencyConfig;
        this.availableCurrencies = value.availableCurrencies;
        this.defaultCurrency = value.defaultCurrency;

        for (let i = 0; i < this.availableCurrencies.length; i++) {
            const sourceObject = this.availableCurrencies[i];
            // Check if an object with the same id exists in the target array
            if (this.defaultCurrency !== sourceObject.currency && !this.currencyExists(this.currencies, 'currency', sourceObject.currency)) {
                // If it doesn't exist, add it to the target array
                this.currencies.push({ amount: undefined, currency: sourceObject.currency });
            }
        }
        this.title = value.title;
        this.signaler.signal('updated');
    }

    filterValidCurrencies(currencies) {
        return currencies
            .filter((currency) => currency.amount && currency.currency);
    }

    async ok() {

        await this.controller.ok({
            currencies: this.filterValidCurrencies(this.currencies),
        });
    }

    addNewCurrency() {
        this.currencies = this.currencies ? [
            ...this.currencies,
            { amount: undefined, currency: undefined },
        ] :  [{amount: undefined, currency: undefined}];
    }

    removeCurrency(index){
        this.currencies = this.currencies.filter((curr,i)=>{
            return i !== index
        })
    }

    currencyExists(arr, property, value) {
        return arr.some(obj => obj[property] === value);
    }
}
