import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useAureliaI18n } from '../react-hooks/useAureliaI18n';
import useLocalStorage from '../react-hooks/useLocalStorage';
import useAureliaRouter from '../react-hooks/useAureliaRouter';
import { AuthService } from '../auth/auth-service';
import { Container } from 'aurelia-framework';
import TopNavBar from './components/TopNavBar';
import { useClient } from '../react-hooks/useClient';
import MenuBar from './components/MenuBar';
import MobileNav from './components/MobileNav';

let authService = Container.instance.get(AuthService);

const ReactMenu = ({ config, user, menu }) => {
  const [refreshToken, setRefreshToken] = useLocalStorage('refresh_token', '');
  const [accessToken, setAccessToken] = useLocalStorage('access_token', '');

  const router = useAureliaRouter();
  const { t } = useAureliaI18n();
  const client = useClient();

  const logout = useCallback(() => {
    client
      .post('user/logout', {
        refresh_token: refreshToken,
        access_token: accessToken,
      })
      .then(() => {
        authService.logoutAfterError();
      })
      .catch(() => {
        authService.logoutAfterError();
      });
  }, []);


  return (
    <>
      {config.get('staging') && (
        <div className={`staging ${config.get('stagingClass')}`}>
          <div className={config.get('stagingClass')}>
            {t(config.get('stagingTitle') || 'sio.staging_environment')}
          </div>
        </div>
      )}

      {router &&
        router?.currentInstruction?.config?.settings?.hideNav !== true && (
          <nav id="sio-navigation" className="navbar">
            <TopNavBar router={router} user={user} logout={logout} />
            <MenuBar menu={menu} router={router} user={user} />
            <MobileNav user={user} router={router} logout={logout} />
            {router?.isNavigating && (
              <div className="menu-spinner pull-right">
                <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
              </div>
            )}
          </nav>
        )}
    </>
  );
};

export default ReactMenu;
