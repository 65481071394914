import React, { useRef, useState } from 'react';
import $ from 'jquery';
import { useAureliaI18n } from '../../react-hooks/useAureliaI18n';
import SioIcon from '../../icon/rsuite-icon-font/SioIcon';
import useMenuPin from './useMenuPin';
import AllMenuItems from './AllMenuItems';

const MenuSearchInput = ({ value, onChange, onClear }) => {
  const inputRef = useRef(null);
  const setFocus = () => {
    inputRef.current.focus();
  };
  return (
    <div className="menu-search-input">
      <input
        value={value}
        onChange={(e) => onChange(e)}
        className="form-control"
        ref={inputRef}
      />
      <SioIcon
        icon={`fa ${!value ? 'fa-search' : 'fa-times'}`}
        onClick={value ? onClear : setFocus}
      />
    </div>
  );
};

const MenuBar = ({ menu, router, user }) => {
  const { t } = useAureliaI18n();
  const [search, setSearch] = useState('');
  const [isPinned, togglePin, pinned] = useMenuPin(user);

  const isActive = (item, currentInstruction) => {
    let currentParams = currentInstruction?.params;

    if (currentParams?.moduleId + '/' + currentParams?.viewId === item?.view) {
      return true;
    }

    if (item.items) {
      if (item.items.groups) {
        for (let group of Object.keys(item.items.groups)) {
          for (let subItem of item.items.groups[group]) {
            if (
              currentParams?.moduleId + '/' + currentParams?.viewId ===
              subItem.view
            ) {
              return true;
            }
          }
        }
      }

      for (let subItem of item.items.noGroup) {
        if (
          currentParams?.moduleId + '/' + currentParams?.viewId ===
          subItem.view
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const closeMenu = (label) => {
    $('#navbar').collapse('hide');
    if (label) {
      setSearch('');
    }
  };

  const onSearchInputChange = (inputText) => {
    setSearch(inputText);
  };

  const isPresentInSearch = (group, subItem, label) => {
    if (!search) {
      return true;
    }
    const isTextIncludeInSearch =
      t(subItem).toLowerCase().includes(search.toLocaleLowerCase()) ||
      group.toLowerCase().includes(search.toLocaleLowerCase());

    return isTextIncludeInSearch ? true : false;
  };

  const toggleMenuPin = ({ e, label, href }) => {
    e.preventDefault();
    togglePin({ label: label, href: href });
  };

  const ItemLabel = ({ subItem, item, href }) => {
    const link =
      href ||
      `/view/${subItem.view.split('/')[0]}/${subItem.view.split('/')[1]}`;
    return (
      <div className="pin-anchor">
        <a href={link} onClick={() => closeMenu(t(item.label))}>
          <span className="nav-label">{t(subItem.label)}</span>
        </a>
        <div
          className="menu-item-pin"
          onClick={(e) => {
            toggleMenuPin({ e, label: subItem.label, href: link });
          }}
        >
          <i
            className="fa fa-thumbtack"
            style={{
              color: isPinned({
                href: link,
              })
                ? '#c45453'
                : '',
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div id="navbar" className="collapse navbar-collapse">
      <ul className="nav navbar-nav">
        {pinned.length > 0 && (
          <li className="dropdown">
            <a
              className="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="nav-icon">
                <span className={'fa fa-thumbtack'}></span>
              </span>
              <span className="nav-label ">{'Gepinnt'}</span>
            </a>
            <ul className="dropdown-menu">
              <li className="menu-header hidden-xs">{'Gepinnt'}</li>
              {pinned.map((pin) => (
                <li key={pin.label} className={`menu-subitem `}>
                  <ItemLabel subItem={{ label: pin.label }} href={pin.href} />
                </li>
              ))}
            </ul>
          </li>
        )}
        {menu &&
          menu.map((item, index) => (
            <React.Fragment key={item.name + index }>
              {!item.items && !item.user && (
                <li
                  className={
                    isActive(item, router?.currentInstruction) ? 'active' : ''
                  }
                >
                  <a
                    href={`/view/${item.view.split('/')[0]}/${
                      item.view.split('/')[1]
                    }`}
                  >
                    {item.icon && (
                      <span className="nav-icon">
                        <span className={item.icon}></span>
                      </span>
                    )}
                    <span className="nav-label visible-xs">
                      {t(item.label)}
                    </span>
                    <span className="nav-label hidden-xs">
                      {item.shortLabel ? t(item.shortLabel) : t(item.label)}
                    </span>
                  </a>
                </li>
              )}
              {item.items && item.name !== 'user' && (
                <li
                  className={`dropdown ${
                    isActive(item, router?.currentInstruction) ? 'active' : ''
                  }`}
                >
                  <>
                    {!item.view ? (
                      <a
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {item.icon && (
                          <span className="nav-icon">
                            <span className={item.icon}></span>
                          </span>
                        )}
                        <span className="nav-label visible-xs">
                          {t(item.label)}
                        </span>
                        {item.shortLabel && (
                          <span className="nav-label hidden-xs">
                            {t(item.shortLabel)}
                          </span>
                        )}
                      </a>
                    ) : (
                      <a
                        href={`/view/${item.view.split('/')[0]}/${
                          item.view.split('/')[1]
                        }`}
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {item.icon && (
                          <span className="nav-icon">
                            <span className={item.icon}></span>
                          </span>
                        )}
                        <span className="nav-label visible-xs">
                          {t(item.label)}
                        </span>
                        {item.shortLabel && (
                          <span className="nav-label hidden-xs">
                            {t(item.shortLabel)}
                          </span>
                        )}
                      </a>
                    )}
                    <ul
                      className="dropdown-menu"
                      onMouseLeave={() => {
                        setSearch('');
                      }}
                    >
                      {/* menu search input */}
                      <MenuSearchInput
                        value={search}
                        onChange={(e) => {
                          onSearchInputChange(e.target.value);
                        }}
                        onClear={() => {
                          if (search) {
                            setSearch('');
                          }
                        }}
                      />
                      {!search && (
                        <li className="menu-header hidden-xs">
                          {t(item.label)}
                        </li>
                      )}
                      {!search &&
                        item.items.groups &&
                        Object.keys(item.items.groups).map((group, index) => (
                          <React.Fragment key={ group + index}>
                            {item.items.groups[group].map(
                              (subItem, i) =>
                                isPresentInSearch(
                                  t(group),
                                  subItem.label,
                                  t(item.label)
                                ) && (
                                  <React.Fragment key={ subItem.label + i}>
                                    {i === 0 && (
                                      <li className="menu-subheader hidden-xs">
                                        {t(group)}
                                      </li>
                                    )}
                                    <li
                                      key={subItem.label}
                                      className={`menu-subitem ${
                                        isActive(
                                          subItem,
                                          router?.currentInstruction
                                        )
                                          ? 'active'
                                          : ''
                                      }`}
                                    >
                                      <ItemLabel
                                        subItem={subItem}
                                        item={item}
                                      />
                                    </li>
                                  </React.Fragment>
                                )
                            )}
                          </React.Fragment>
                        ))}
                      {!search &&
                        item.items.noGroup &&
                        item.items.noGroup.map(
                          (subItem) =>
                            isPresentInSearch(
                              'noGroup',
                              subItem.label,
                              t(item.label)
                            ) && (
                              <li
                                key={subItem.label}
                                className={
                                  isActive(subItem, router?.currentInstruction)
                                    ? 'menu-subitem active'
                                    : 'menu-subitem '
                                }
                              >
                                <ItemLabel subItem={subItem} item={item} />
                              </li>
                            )
                        )}

                      {search && (
                        <AllMenuItems
                          router={router}
                          menu={menu}
                          isPresentInSearch={isPresentInSearch}
                          ItemLabel={ItemLabel}
                          isActive={isActive}
                        />
                      )}
                    </ul>
                  </>
                </li>
              )}
            </React.Fragment>
          ))}
      </ul>
    </div>
  );
};

export default MenuBar;
