import { customElement, inject } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import {FlashService} from "../flash/flash-service";

import "./notification.less";

@customElement("sio-notification")
@inject(EventAggregator, FlashService)
export class Notification {
    constructor(ea, flash) {
        this.ea = ea;
        this.flash = flash;
        this.notificationsList = [];
        this.notificationSubscriber = ea.subscribe(
            "sio_notification",
            (data) => {
                const notification = {
                    id: this.notificationsList.length + 1,
                    title: data?.title,
                    body: data?.body,
                    type: data?.type,
                    link: data?.link,
                    action: data?.action,
                };
                this.notificationsList.unshift(notification);
                setTimeout(() => {
                    this.clearNotification(notification.id);
                }, 120 * 1000);
            }
        );
    }

    detached() {
        this.notificationSubscriber.dispose();
    }
    clearNotification(id) {
        this.notificationsList = this.notificationsList.filter(
            (item) => item.id !== id
        );
    }

    copyNumber(notificationBody, id) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(notificationBody, "text/html");
        const span = doc.querySelector("span");
        const textInsideSpan = span ? span.textContent : null;
        navigator.clipboard.writeText(textInsideSpan);
        this.clearNotification(id);
        this.flash.success("Rufnummer in die Zwischenablage kopiert")
    }
}
