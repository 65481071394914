

export const sum = (prices, ignoreMissing = true) => {

    let sum = null;

    for (let price of prices) {

        if (!price) {
            if (ignoreMissing) {
                continue;
            } else {
                return null;
            }
        }

        if (sum && price.currency != sum.currency) {
            return null;
        }

        sum = sum ? {
            amount: sum.amount + price.amount,
            currency: price.currency
        } : price;

    }

    return sum;
}
