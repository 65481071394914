import {inject, customElement} from 'aurelia-framework';
import {Client} from '../api/client';
import {ChoiceLoader} from '../choice/loader/choice-loader';
import * as _ from 'lodash';

/**
 * Todo use general table view here
 */
@customElement('moebelnet-keyword-tool')
@inject(Client, ChoiceLoader)
export class MoebelnetKeywordTools
{
    results = [];

    choiceField = {
        modelId: 'moebelnet/property-list'
    };

    constructor(client, choiceLoader)
    {
        this.client = client;
        this.choiceLoader = choiceLoader;
    }

    async attached()
    {
        this.propertyLists = await this.choiceLoader.getChoices({'modelId': 'moebelnet/property-list'});

        this.propertyLists = _.orderBy(this.propertyLists, ['label'], ['asc']);
    }

    async search()
    {
        let results = await this.client.get('moebelnet/keyword/' + this.keyword);

        this.results = results.keywords;

        this.words = [];

        _.each(results.terms, (item, word) => {
            this.words.push({
                word: word,
                count: item.count,
                words: item.words,
                list: item.list
            });
        });

        this.words = _.orderBy(this.words, ['count'], ['desc']);
    }

    deleteWord(index)
    {
        this.words.splice(index, 1);
    }

    async addToKeyword()
    {
        if (this.keyword == null || this.keyword == '') {
            return;
        }

        await this.client.post('moebelnet/add-keyword-to-content-map/' + this.keyword);
    }

    async save(relation)
    {
        let filteredWords = _.filter(this.words, function (o) {
           return o.list;
        });

        let body = {
            words: filteredWords
        };

        if (relation) {
            body.saveWithFrom = this.keyword;
        }

        let result = await this.client.post('moebelnet/keyword', body);
    }
}

