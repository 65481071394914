// not using r-suit stack because it was causing too many re-rendring when prop changes

import React from 'react';

const Stack = ({ children }) => {
  return (
    <div
      className={'hidden-xs hover-menu rs-stack'}
      style={{ alignItems: 'center', gap: '2px' }}
    >
      {children}
    </div>
  );
};

export default Stack;
