import React, { useState } from 'react';
import useLocalStorage from '../../react-hooks/useLocalStorage';
import { useClient } from '../../react-hooks/useClient';

const useMenuPin = (user) => {
  const client = useClient();
  const defaultPins = user?.settings?.userSettings?.pinnedMenuItems || '[]';
  const parsedPins = user?.settings?.userSettings?.pinnedMenuItems
    ? defaultPins?.map((item) => JSON.parse(item.trim()))
    : [];
  const [pinned, setPinned] = useState(parsedPins);

  const isPinned = (item) => pinned?.some((pin) => pin?.href === item?.href);

  const updatePinsInServer = async (pins) => {
    const jsonPins = pins.map((p) => JSON.stringify(p));
    const data = await client.put(
      `user/settings-pinned-menu-items/${user.id}`,
      {
        settings: { userSettings: { pinnedMenuItems: jsonPins } },
      }
    );
    return data;
  };

  const togglePin = (item) => {
    if (isPinned(item)) {
      const newPins = pinned.filter((pin) => pin?.href !== item.href);
      updatePinsInServer(newPins);
      setPinned(newPins);
    } else {
      const newPins = [item, ...(pinned || [])];
      updatePinsInServer(newPins);
      setPinned(newPins);
    }
  };

  return [isPinned, togglePin, pinned];
};

export default useMenuPin;
