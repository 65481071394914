import React, { forwardRef } from 'react';
import { Button } from 'rsuite';

const NavButton = forwardRef(
  ({ children, onClick, title, className, style = {} }, ref) => {
    return (
      <Button
        onClick={onClick}
        size="sm"
        title={title}
        className={`sio-menu-button ${className ? className : ''}`}
        ref={ref}
        style={{ ...style }}
      >
        {children}
      </Button>
    );
  }
);

export default NavButton;
