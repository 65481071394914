import React, { useState } from 'react';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';
import NavButton from './NavButton';
import { Modal, ButtonGroup, Button, Dropdown } from 'rsuite';

const Locales = () => {
  const { t, locale } = useAureliaI18n();
  const { contentLocale, setContentLocale, allLocales } = locale || {};
  const [open, setOpen] = useState(false);
  const currentLocale = allLocales?.find((l) => l == contentLocale);

  const renderActiveLocale = (props, ref) => {
    return (
      <NavButton
        style={{
          textTransform: 'uppercase',
          fontSize: '14.3px',
          fontWeight: 'bold',
        }}
        {...props}
        ref={ref}
      >
        {currentLocale || ''}
      </NavButton>
    );
  };
  return (
    allLocales.length > 1 && (
      <>
        <Dropdown
          title={currentLocale}
          noCaret={true}
          renderToggle={renderActiveLocale}
        >
          {allLocales.map(
            (locale) =>
              locale !== currentLocale && (
                <Dropdown.Item
                  key={locale}
                  onClick={() => setContentLocale(locale)}
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '14.3px',
                  }}
                >
                  {locale}
                </Dropdown.Item>
              )
          )}
        </Dropdown>
      </>
    )
  );
};

export default Locales;
