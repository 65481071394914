import React, { useEffect, useState, memo, useCallback, useRef } from 'react';
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  Loader,
  Pagination,
  Panel,
  Stack,
  Tag,
  TagGroup,
  Toggle,
  Popover,
  Whisper,
  Input,
  InputGroup,
} from 'rsuite';
import debounce from 'debounce-promise';
import { Container } from 'aurelia-dependency-injection';
import moment from 'moment-timezone';
import { WorkflowService } from '../../../workflow/workflow-service';
import ReactChoiceDisplay from '../../../editor/blocks/react-choice-display';
import SearchIcon from '@rsuite/icons/Search';
import { useClient } from '../../../react-hooks/useClient';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';
import NavButton from './NavButton';
import { EventAggregator } from 'aurelia-event-aggregator';
import { useMemo } from 'react';

const getFilter = ({
  filterReadOnly,
  filterInstalled,
  searchField,
  userId,
}) => {
  const filter = {};

  if (filterReadOnly && !searchField) {
    filter['users'] = { $ne: userId };
  }

  if (filterInstalled && !searchField) {
    filter['installedModules.0'] = { $exists: true };
  }
  if (searchField) {
    filter['search'] = searchField;
  }
  return JSON.stringify(filter);
};

const fetchReleaseNotes = async ({
  page,
  pageSize,
  readOnly,
  installed,
  searchField,
  userId,
  client,
}) => {
  const filter = getFilter({
    filterReadOnly: readOnly,
    filterInstalled: installed,
    searchField: searchField,
    userId: userId,
  });

  const params = new URLSearchParams({
    offset: '0',
    offset: (page - 1) * pageSize,
    limit: pageSize,
    conditions: filter,
    loadCount: true,
  });
  params.append('sort[0][]', ['createdAt']);
  params.append('sort[0][]', ['DESC']);
  let url = `release-note/release-note?${params.toString()}`;
  let response = await client.get(url);
  return response;
};

const getUnreadItems = async ({ client, userId }) => {
  const filters = getFilter({
    filterReadOnly: true,
    filterInstalled: true,
    searchField: '',
    userId: userId,
  });
  const url = `release-note/release-note?offset=0&limit=1&conditions=${filters}&loadCount=true`;
  const response = await client.get(url);
  return response;
};

const ReleaseNotes = memo(({ userId }) => {
  const client = useClient();
  const { t } = useAureliaI18n();
  const workflowService = Container.instance.get(WorkflowService);
  const ea = Container.instance.get(EventAggregator);

  const [open, setOpen] = useState(false);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [readOnly, setReadOnly] = useState(true);
  const [installed, setInstalledOnly] = useState(true);

  const [totalUnread, setTotalUnread] = useState(0);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [searchField, setSearchField] = useState('');

  const pageSize = 10;

  const fetchFilteredData = useCallback(
    async (params) => {
      const {
        currentPage,
        currentPageSize,
        currentReadOnly,
        currentInstalled,
        currentSearchField,
      } = params;
      setLoading(true);
      try {
        const response = await fetchReleaseNotes({
          page: currentPage,
          pageSize: currentPageSize,
          readOnly: currentReadOnly,
          installed: currentInstalled,
          searchField: currentSearchField,
          client,
          userId,
        });
        setTotal(response.filteredCount);
        setItems(response.items);
        const unreadCount = await getUnreadItems({ client, userId });
        setTotalUnread(unreadCount?.filteredCount);
      } finally {
        setLoading(false);
      }
    },
    [client, userId]
  );

  const debouncedFetchData = useMemo(
    () => debounce((params) => fetchFilteredData(params), 1000),
    [fetchFilteredData]
  );

  useEffect(() => {
    debouncedFetchData({
      currentPage: page,
      currentPageSize: pageSize,
      currentReadOnly: readOnly,
      currentInstalled: installed,
      currentSearchField: searchField,
    });
  }, [page, pageSize, readOnly, installed, searchField]);

  const triggerWorkflow = async (workflow, id) => {
    await workflowService.trigger(workflow, id ? [id] : null);
    debouncedFetchData({
      currentPage: page,
      currentPageSize: pageSize,
      currentReadOnly: readOnly,
      currentInstalled: installed,
      currentSearchField: searchField,
    });
  };

  useEffect(() => {
    const subscription = ea.subscribe('sio_form_post_submit', (response) => {
      if (
        response?.config?.modelId === 'release-note/release-note' &&
        response?.config?.serverSide
      ) {
        console.debug('SERVER SIDE UPDATE : RELEASE NOTES');
        setPage(1);
        debouncedFetchData({
          currentPage: page,
          currentPageSize: pageSize,
          currentReadOnly: readOnly,
          currentInstalled: installed,
          currentSearchField: searchField,
        });
      }
    });
    return () => {
      if (subscription) {
        subscription.dispose();
      }
    };
  }, []);

  const isMarkedByUser = (users) => {
    return users.some((user) => user.id === userId);
  };
  const radioStyle = {
    padding: '9px 0px',
  };
  return (
    <>
      <NavButton
        className="hover-button"
        onClick={() => setOpen(true)}
        title={t('release-note.what-is-new')}
      >
        <div className="sio-badge-link">
          <i className="fa-sharp fa-solid fa-star fa-sm"></i>
          {totalUnread > 0 && <span className="badge">{totalUnread}</span>}
        </div>
      </NavButton>
      <Drawer
        open={open}
        onClose={() => {
          setOpen(false);
          setSearchField('');
        }}
      >
        <Drawer.Header
          closeButton={false}
          style={{
            padding: '0px',
            paddingBottom: '0',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{ display: 'flex', paddingBottom: '1rem', padding: '20px' }}
          >
            <Drawer.Title style={{ fontWeight: 'bold' }}>
              {t('release-note.what-is-new')}
            </Drawer.Title>
            <Drawer.Actions>
              <Stack
                style={{ height: '100%' }}
                direction={'row'}
                spacing={10}
                alignItems="center"
                childrenRenderMode="clone"
              >
                {!searchField && (
                  <>
                    <Whisper
                      placement="bottom"
                      trigger="hover"
                      speaker={
                        <Popover style={{ padding: '5px' }}>
                          {t('release-note.show-only-installed')}
                        </Popover>
                      }
                    >
                      <Toggle
                        style={{ marginBottom: 0 }}
                        checked={installed}
                        onChange={setInstalledOnly}
                      >
                        {t('release-note.only-installed')}
                      </Toggle>
                    </Whisper>

                    <Whisper
                      placement="bottom"
                      trigger="hover"
                      speaker={
                        <Popover style={{ padding: '5px' }}>
                          {t('release-note.show-only-read')}
                        </Popover>
                      }
                    >
                      <Toggle
                        style={{ marginBottom: 0 }}
                        checked={readOnly}
                        onChange={setReadOnly}
                      >
                        {t('release-note.only-unread')}
                      </Toggle>
                    </Whisper>
                  </>
                )}
                <Button
                  appearance="subtle"
                  onClick={() => {
                    triggerWorkflow('release-note/batch-read-mark');
                  }}
                >
                  {'Alle als gelesen markieren'}
                </Button>
              </Stack>
            </Drawer.Actions>
          </div>
          {
            <div
              style={{
                background: '#f1f1f1',
                padding: '20px',
              }}
            >
              <InputGroup
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Input
                  onChange={(value) => {
                    setLoading(true);
                    setSearchField(value);
                  }}
                  placeholder="Suche"
                />
                <InputGroup.Button>
                  <SearchIcon />
                </InputGroup.Button>
              </InputGroup>
            </div>
          }
        </Drawer.Header>
        <Drawer.Body style={{ padding: 0, backgroundColor: '#f3f3f3' }}>
          <Stack
            divider={<Divider style={{ margin: 0 }}></Divider>}
            direction={'column'}
            alignItems={'stretch'}
            style={{ height: '100%' }}
            className={'release-note-stack'}
          >
            <Stack.Item style={{ padding: '20px' }}>
              {loading && <Loader center size="lg" backdrop />}

              {!loading && items.length === 0 && (
                <h5 style={{ textAlign: 'center' }}>
                  {t('release-note.all-read')}
                </h5>
              )}

              {!loading &&
                items &&
                items.length > 0 &&
                items.map((item) => (
                  <Panel
                    style={{ backgroundColor: '#fff', marginBottom: 20 }}
                    key={item.id}
                    shaded
                    header={
                      <div>
                        <Stack justifyContent="space-between">
                          <div>
                            <span>
                              <span style={{ fontWeight: 'bold' }}>
                                {item.title}
                              </span>
                              <Whisper
                                placement="bottom"
                                trigger="hover"
                                speaker={
                                  <Popover style={{ padding: '5px' }}>
                                    <span>
                                      {t('release-note.announcement-date')}
                                    </span>
                                  </Popover>
                                }
                              >
                                <small>
                                  &nbsp;&nbsp;-{' '}
                                  {moment(item.createdAt).format('L')}
                                  &nbsp;&nbsp;
                                </small>
                              </Whisper>
                            </span>
                            {!item.isDeployed ? (
                              <Whisper
                                placement="bottom"
                                trigger="hover"
                                speaker={
                                  <Popover style={{ padding: '5px' }}>
                                    {t('release-note.upcoming-release-long')}
                                  </Popover>
                                }
                              >
                                <Tag size="sm" color={'yellow'}>
                                  {t('release-note.upcoming-release')}
                                </Tag>
                              </Whisper>
                            ) : null}
                          </div>
                          {isMarkedByUser(item.users) ? (
                            <Whisper
                              placement="bottom"
                              trigger="hover"
                              speaker={
                                <Popover
                                  style={{
                                    padding: '5px',
                                    marginRight: '2rem',
                                  }}
                                >
                                  <span>
                                    {t('release-note.mark-as-unread')}
                                  </span>
                                </Popover>
                              }
                            >
                              <IconButton
                                style={radioStyle}
                                icon={
                                  <i className="fa fa-circle-check fa-lg"></i>
                                }
                                appearance="subtle"
                                onClick={() => {
                                  triggerWorkflow(
                                    'release-note/mark-unread',
                                    item.id
                                  );
                                }}
                              ></IconButton>
                            </Whisper>
                          ) : (
                            <Whisper
                              placement="bottom"
                              trigger="hover"
                              speaker={
                                <Popover
                                  style={{
                                    padding: '5px',
                                    marginRight: '2rem',
                                  }}
                                >
                                  {t('release-note.mark-as-read')}
                                </Popover>
                              }
                            >
                              <IconButton
                                style={radioStyle}
                                icon={
                                  <i className="fa-regular fa-circle fa-lg"></i>
                                }
                                appearance="subtle"
                                onClick={() => {
                                  triggerWorkflow(
                                    'release-note/mark-read',
                                    item.id
                                  );
                                }}
                              ></IconButton>
                            </Whisper>
                          )}
                        </Stack>
                        <TagGroup>
                          {(item.installedModules ?? []).map((tag, index) => {
                            return (
                              <Whisper
                                key={index}
                                placement="bottom"
                                trigger="hover"
                                speaker={
                                  <Popover style={{ padding: '5px' }}>
                                    {t('release-note.installed')}
                                  </Popover>
                                }
                              >
                                <Tag key={index} size="sm" color={'blue'}>
                                  <ReactChoiceDisplay
                                    config={{ set: 'allowedModules' }}
                                    value={tag}
                                  />
                                </Tag>
                              </Whisper>
                            );
                          })}
                          {(item.notInstalledModules ?? []).map(
                            (tag, index) => {
                              return (
                                <Whisper
                                  placement="bottom"
                                  trigger="hover"
                                  speaker={
                                    <Popover style={{ padding: '5px' }}>
                                      {t('release-note.not-installed')}
                                    </Popover>
                                  }
                                >
                                  <Tag
                                    key={index}
                                    size="sm"
                                    color={'blue'}
                                    className={'rs-tag-light-blue'}
                                  >
                                    <ReactChoiceDisplay
                                      config={{ set: 'allowedModules' }}
                                      value={tag}
                                    />
                                  </Tag>
                                </Whisper>
                              );
                            }
                          )}
                        </TagGroup>
                      </div>
                    }
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></span>
                  </Panel>
                ))}
            </Stack.Item>
            {total > pageSize && (
              <Stack
                direction={'row'}
                justifyContent={'center'}
                className="sio-pagination"
                style={{ marginBottom: '70px' }}
              >
                <Pagination
                  style={{ padding: '20px' }}
                  prev={true}
                  next={true}
                  total={total}
                  limit={pageSize}
                  maxButtons={3}
                  activePage={page}
                  onChangePage={setPage}
                />
              </Stack>
            )}
          </Stack>
        </Drawer.Body>
      </Drawer>
    </>
  );
});

export default ReleaseNotes;
