import {customElement, bindable, inject, LogManager} from 'aurelia-framework';
import {Client} from '../api/client';
import * as _ from 'lodash';

const logger = LogManager.getLogger('property-display');

/**
 * Class to display a property of an object
 */
@customElement('property-display')
@inject(Client)
export class PropertyDisplay
{
    @bindable object;
    @bindable field;

    column;

    constructor(client)
    {
        this.client = client;
    }

    bind()
    {
        this.objectChanged();
    }

    objectChanged()
    {
        this.column = null;

        if (!this.field) {
            return;
        }

        this.client.get(this.object.modelId + '/list', 60).then(list => {

            let column = _.find(list.columns, (o) => { return o.property === this.field});

            if (!column) {
                logger.error('Column not found ' + this.field);
                return;
            }

            this.column = column;
        });
    }
}