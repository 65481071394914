import {bindable, customElement, inject, BindingEngine} from "aurelia-framework";
import {Client} from "../api/client";
import {FormField} from "../form/object/form-field";

@inject(Client, BindingEngine)
@customElement('object-conditions-input')
export class ObjectConditionsInput {

    @bindable field;
    @bindable({defaultBindingMode: 2}) value;

    types = [];
    fields = [];

    static Types = new Map;

    client;
    binding;
    subscription;

    constructor(client, binding) {
        this.client = client;
        this.binding = binding;
        this.childErrorsChanged = this.childErrorsChanged.bind(this);
    }

    dispose() {
        if (this.subscription) {
            this.subscription.dispose();
        }
    }

    bind() {
        this.fieldChanged();
        this.valueChanged();
    }

    fieldChanged() {
        const promises = [];
        this.types.splice(0, 99999); // re-use array to avoid Aurelia glitches

        // avoid requesting same stuff again and again

        console.log('OBject id', this.field.options);

        for (let modelId of this.field.options.modelIds) {

            if (!ObjectConditionsInput.Types.has(modelId)) {
                const promise = this.client.get('form/choice/object-condition/' + modelId);
                ObjectConditionsInput.Types.set(modelId, promise);
            }

            const choices = ObjectConditionsInput.Types.get(modelId);

            if (choices instanceof Promise) {
                promises.push(choices);
            } else {
                promises.push(Promise.resolve(choices));
            }
        }

        Promise.all(promises)
            .then(data => data.map(choices => {
                for (let choice of choices) {
                    if (!this.types.find(c => c.value === choice.value)) {
                        this.types.push(choice);
                    }
                }
            }))
            .then(() => {
                this._buildFields()
            });

        if (this.subscription) {
            this.subscription.dispose();
        }

        this.subscription = this.binding.propertyObserver(this.field, 'childErrors').subscribe(this.childErrorsChanged);
    }

    valueChanged() {
        if (!this.value) {
            this.value = {modelId: undefined};
        }

        this._buildFields();
    }

    change(field) {
        this.value[field.property] = field.value;

        if ('modelId' === field.property) {
            this._buildFields();
        }

        this.field.formService.change(this.field);
    }

    childErrorsChanged() {
        Object.entries(this.field.childErrors).forEach(([property, errors]) => {

            const field = this.fields.find(f => f.property === property);
            field && field.setErrors(errors);
        });
    }

    _buildFields() {
        this.fields.splice(0, 9999);

        this.fields.push(
            new FormField(this,
                {
                    property: 'modelId',
                    type: 'choice',
                    label: 'Typ',
                    choices: this.types,
                    required: true
                },
                this.field.fullProperty + '.modelId',
                this.value.modelId,
                this.field.parent
            )
        );

        if (this.value.modelId && this.types.length) {
            this.types
                .find(choice => choice.value === this.value.modelId).fields
                .forEach(field => this.fields.push(
                    new FormField(
                        this,
                        field,
                        this.field.fullProperty + '.' + field.property,
                        this.value[field.property],
                        this.field.parent
                    )
                ));
        }

        if (2 === this.fields.length) {
            for (let field of this.fields) {
                field.groupClass = 'col-xs-12 col-sm-6';
            }
        }
    }
}
