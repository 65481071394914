import React from 'react';
import ReactDOM from 'react-dom';
import {bindable, customElement, inject, noView} from 'aurelia-framework';
import {SIOGoogleMap} from './google-map/sio-google-map';

import {Client} from "../api/client";

@noView()
@inject(Element, Client)
@customElement('sio-map')
export class SioMap {

    @bindable markers;
    @bindable polylines;
    @bindable apiKey;
    @bindable language;

    reactComponent = {};
    client;

    constructor(element, client) {
        this.element = element;
        this.client = client;
    }


    render() {

        this.reactComponent = ReactDOM.render(
            <SIOGoogleMap apiKey={this.apiKey} language={this.language} markers={this.markers}/>,
            this.element
        );
    }

    async bind() {
        let settings = await this.client
            .get('maps/google/apikey', true);
        if (typeof settings !== 'undefined') {
            this.apiKey = settings.apiKey ?? '';
            this.language = settings.locale ?? 'en';
        }

        this.render();
    }

    markersChanged(newVal) {
        this.bind();
    }

    polylinesChanged(newVal) {
        this.bind();
    }

}

