import {bindable, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {FormField} from "../../form/object/form-field";
import {DialogController} from 'aurelia-dialog';
import {FlashService} from "../../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";

@inject(
    Client,
    DialogController,
    FlashService,
    EventAggregator
)
export class ShareMediaBundleView
{
    @bindable mediaBundle;

    constructor(
        client,
        dialogController,
        flash,
        ea
    ) {
        this.client = client;
        this.dialogController = dialogController;
        this.flash = flash;
        this.ea = ea;

        const dummyFormService = {
            change: () => {}
        };

        this.emailsField = new FormField(
            dummyFormService,
            {
                type: 'tags',
                label: 'sio.field.emails',
                required: 'true'
            },
            'emails',
            null
        );

        this.loading = false;
    }

    ok()
    {
        this.loading = true;

        if (!this.mediaBundle || !this.mediaBundle.id || this.mediaBundle.modelId !== 'media-database/media-bundle') {
            this.dialogController.cancel();
            this.flash.error('sio.failed');
            throw new Error("Reference to mediaBundle object is broken");
        }

        return this.shareMediaBundle(this.emailsField.getValue()).then(
            (response) => {
                // Don't set loading to false, because dialog will be closed anyway.
                // If you set loading = false here, user will see blink of dialog controls.
                //this.loading = false;

                return Promise.resolve(this.dialogController.ok())
            }
        ).catch(
            (error) => {
                this.loading = false;

                throw error;
            }
        );
    }

    shareMediaBundle(emails)
    {
        return this.client.patch('media-bundle/' + this.mediaBundle.id + '/_share', {emails})
            .then(response => {
                this.ea.publish('sio_form_post_submit', {config: {modelId: 'log/log'}});
                this.ea.publish('sio_form_post_submit', {config: {modelId: 'media-database/media-bundle'}});
                this.flash.success('sio.successful');

                return response;
            }, error => {
                this.flash.error('sio.failed');

                throw error;
            })
        ;
    }
}
