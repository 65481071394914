import { bindable, inject } from "aurelia-framework";
import $ from "jquery";

import "./dropdown-menu.less";

$(window).on("mousedown", () => {
    // Close all actions menus when click is not in menu
    $(".dropdown-menu-items").hide();
});

$(window).on("blur", () => {
    // Close all actions menus when focus is lost
    $(".dropdown-menu-items").hide();
});

@inject(Element)
export class DropdownMenu {
    @bindable actions;
    @bindable label;
    @bindable title;
    @bindable context;
    @bindable icon = 'fa fa-ellipsis-vertical';
    @bindable menuBtnClass = 'btn btn-default btn-sm';
    @bindable style = '';
    @bindable maxHeight = 400;

    @bindable onAfterSuccess = undefined; // Hook: Called after successful action execution

    @bindable callback;

    constructor(element) {
        this.element = element;
    }

    attached() {
        this.hasCallback = this.callback != null;
        this.maxHeightChanged();
    }

    callbackWrapper(action, context) {
        //Wrapper for this set correctly in callback
        this.callback({action, context});
    }

    onAfterSuccessWrapper() {
        if (this.onAfterSuccess) {
            this.onAfterSuccess();
        }
    }

    maxHeightChanged() {
        if (this.element) {
            $(this.element)
                .find(".dropdown-menu-items")
                .css("max-height", this.maxHeight);
        }
    }

    toggleActionsMenu(event) {
        const targetItem = $(event.target)
            .parent()
            .find(".dropdown-menu-items");
        const tableActionItems = $(event.target)
            .parent()
            .parent()
            .parent()
            .parent()
            .find(".table-action-items");
        const rect = event.target.getBoundingClientRect();
        const buttonBottom = rect.top + event.target.offsetHeight;
        const width = targetItem.outerWidth() + 5;

        let left = 0;
        let topAdj = 0;
        if (tableActionItems.length > 0) {
            left = tableActionItems.offset().left - width;
            topAdj = rect.height;
        } else {
            left = rect.left - targetItem.outerWidth();
        }

        if (
            document.documentElement.clientHeight <
            this.maxHeight + buttonBottom
        ) {
            targetItem.css({
                top: undefined,
                bottom: document.documentElement.clientHeight - 38 - rect.top + "px",
            });
        } else {
            targetItem.css({
                top: buttonBottom - topAdj + "px",
                bottom: undefined,
            });
        }

        if (targetItem.is(":visible")) {
            $(targetItem).slideUp(200);
        } else {
            const items = $(targetItem);
            items.filter((index) => items[index] !== targetItem[0]).hide();
            if (!tableActionItems.length > 0) {
                targetItem.slideDown(0);
                targetItem.css({
                        "display": "flex",
                        "flex-direction": "column",
                        "visibility":  "hidden"
                });
                const overflowOffset = targetItem.get(0).clientHeight >= targetItem.get(0).scrollHeight ? 0 : 18;
                left = rect.left - targetItem.outerWidth()/2 + overflowOffset;
                targetItem.hide().css({
                    "visibility":  "visible"
                });
            }

            targetItem.slideDown(200);
            targetItem.css({
                "display": "flex",
                "flex-direction": "column",
                "left": left + "px",
            });

        }

        event.stopPropagation();
    }
}
