import React, { useState, useEffect, memo, useCallback } from 'react';
import usePin from '../../react-hooks/usePin';
import { useUserClient } from '../../react-hooks/useUserClient';
import SioLinkWithBadge from './TopNav/SioLinkWithBadge';
import ToDoMenuPopover from './TopNav/ToDoMenuPopover';
import { useAureliaI18n } from '../../react-hooks/useAureliaI18n';
import { useClient } from '../../react-hooks/useClient';
import { ActionButton } from '../../action/react/action-button';
import Logo from './TopNav/Logo';
import Locales from './TopNav/Locales';
import Breadcrumbs from './TopNav/Breadcrumbs';
import NavButton from './TopNav/NavButton';
import TimeTrackingButton from './TopNav/TimeTrackingButton';
import ReleaseNotes from './TopNav/ReleaseNotes';
import Stack from './TopNav/Stack';
import HistoryButton from './TopNav/HistoryButton';

const TopNavBar = ({ user, logout, router }) => {
  const [userClient, hasRole] = useUserClient();
  const [hasCheckListViewPermission, setHasCheckListViewPermission] = useState(
    false
  );
  const { t } = useAureliaI18n();
  const client = useClient();
  const [isPinned, togglePin] = usePin();

  const isPinFeatureEnabled =
    user?.instance?.settings?.instanceGeneral?.enablePinFeature || false;

  const checkListRole = useCallback(async () => {
    const data = await hasRole('checklist-view');
    setHasCheckListViewPermission(data);
  }, []);

  useEffect(() => {
    checkListRole();
  }, [checkListRole]);

  const imageUrl = useCallback((file) => {
    return file.previewUrl.replace('$width', '30').replace('$height', '-');
  }, []);

  return (
    <div className="navbar-header">
      <button
        type="button"
        className="navbar-toggle collapsed mainnav-toggle"
        data-toggle="collapse"
        data-target="#navbar"
        aria-expanded="false"
        aria-controls="navbar"
      >
        <span className="sr-only">Toggle navigation</span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>

      <Logo />

      <div className="navbar-toggle collapsed usernav-toggle">
        <Stack spacing={2} className="hidden-xs hover-menu">
          {user?.impersonatedBy && (
            <NavButton className="no-hover-effect">
              <ActionButton
                config={{
                  type: 'workflow',
                  workflowId: 'user/return-to-original-user',
                  buttonClass:
                    'btn btn-link impersonification-return-button mr-1',
                  label: 'Zurück zu ' + user?.impersonatedBy?.username,
                  reload: true,
                }}
                context={{ data: {} }}
                onSubmitted={() => window.location.reload()}
              />
            </NavButton>
          )}

          <Locales />

          {isPinFeatureEnabled && (
            <NavButton onClick={togglePin}>
              <span>
                <i
                  className={`fa fa-thumbtack fa-sm ${
                    isPinned ? 'text-danger' : ''
                  }`}
                ></i>
              </span>
            </NavButton>
          )}
          {user?.trackingTimeDate && <TimeTrackingButton user={user} />}
          <NavButton
            title={t('notification.go-to-notifications')}
            onClick={() => {
              window.location.href = '/view/notification-center/notifications';
            }}
          >
            <a href="/view/notification-center/notifications">
              <i className={`fa fa-bell fa-sm`}></i>
            </a>
          </NavButton>
          {hasCheckListViewPermission && <ToDoMenuPopover client={client} />}
          {hasCheckListViewPermission &&
            user.activeModules.find((m) => m?.id == 'email-communication') && (
              <NavButton
                onClick={() => {
                  window.location.href =
                    'view/email-communication/messages';
                }}
              >
                <SioLinkWithBadge
                  link={'view/email-communication/messages'}
                  title={'email-communication.messages'}
                  modelId={'email-communication/messages'}
                  icon={'fa fa-envelope fa-sm'}
                  counterLink={'email-communication/messages/dues'}
                />
              </NavButton>
            )}

          <HistoryButton user={user} />

          <ReleaseNotes key="release-notes" userId={user.id} />
          <NavButton className="no-hover-effect" title={user.username}>
            <div>
              {user?.profilePicture && (
                <a
                  href={`/view/user/detail?id=${user.id}`}
                  className="nav-label"
                >
                  <img
                    className="img-circle profile-picture"
                    src={imageUrl(user?.profilePicture)}
                    alt={user.username}
                  />
                </a>
              )}

              {!user?.profilePicture && (
                <a
                  href={`/view/user/detail?id=${user.id}`}
                  className="nav-label"
                  name="my-profile"
                  style={{ fontSize: '14px' }}
                >
                  {user.username}
                </a>
              )}
            </div>
          </NavButton>
          <NavButton title={'Logout'} onClick={logout}>
            <span>
              <i className={`fa fa-right-from-bracket fa-sm`}></i>
            </span>
          </NavButton>
        </Stack>

        <button
          type="button"
          className="navbar-toggle collapsed mainnav-toggle"
          data-toggle="collapse"
          data-target="#navbar-secondary"
          aria-expanded="false"
          aria-controls="navbar-secondary"
        >
          <span className="sr-only">Toggle navigation</span>
          <i className="fa fa-grip mainNavicon"></i>
        </button>
      </div>

      <Breadcrumbs router={router} />
    </div>
  );
};

export default memo(TopNavBar);
