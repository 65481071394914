import React, { useState, useEffect } from 'react';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';
import { useAureliaEvent } from '../../../react-hooks/useAureliaEvent';
import { useClient } from '../../../react-hooks/useClient';

const SioLinkWithBadge = ({ title, link, modelId, icon, counterLink }) => {
  const [overdueTodos, setOverdueTodos] = useState([]);
  const { t } = useAureliaI18n();
  const { eventData, publish } = useAureliaEvent('sio_form_post_submit');
  const client = useClient();

  const loadOverdueCount = async () => {
    const data = await client.get(counterLink);
    setOverdueTodos(Number(data));
  };

  useEffect(() => {
    loadOverdueCount();
  }, []);

  useEffect(() => {
    if (eventData?.config?.modelId === modelId) {
      loadOverdueCount();
    }
  }, [eventData]);

  return (
    <a href={link} title={t(title)} className="sio-badge-link">
      <i className={icon}></i>
      {typeof overdueTodos === 'number' && overdueTodos > 0 ? (
        <span className="badge">{overdueTodos}</span>
      ) : (
        <></>
      )}
    </a>
  );
};

export default SioLinkWithBadge;
